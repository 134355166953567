<template>
    <div class="home">
        <v-card class="my-4">
            <v-card-title>
                {{$t('MAIN.DASH')}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3" sm="6">
                        <v-card
                                class="mx-auto rounded-lg"
                                outlined

                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                       Total AGENTS: {{agent_data.total_agent}}
                                    </div>
                                    <v-list-item-title class="text-h6 mb-1">
                                       {{$t('MAIN.ACTIVE')}}: {{agent_data.active_agent}}
<!--                                        <v-icon size="10" class="blinking-circle" color="success">mdi-circle</v-icon>-->
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{$t('MAIN.PARENT_AGENT')}}: {{agent_data.parent_agent}} ({{$t('MAIN.ACTIVE')}})</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                        tile
                                        size="80"

                                >
                                    <v-icon size="80"  color="primary">mdi-human-male-board-poll</v-icon>
                                </v-list-item-avatar>
                            </v-list-item>

                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3" sm="6">
                        <v-card
                                class="mx-auto rounded-lg"
                                outlined
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                        Personal Accident
                                    </div>
                                    <v-list-item-title class="text-h6 mb-1">
                                        LAK <span class="red--text">{{total_pa_policy.total_premium/1000000 | numeral('0,0.[0]')}}M</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{$t('MAIN.TOTAL_POLICY')}}: {{total_pa_policy.total}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                        tile
                                        size="80"

                                >
                                    <v-icon size="80" color="success">mdi-wheelchair-accessibility</v-icon>
                                </v-list-item-avatar>
                            </v-list-item>

                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-card
                                class="mx-auto rounded-lg"
                                outlined
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                        EZY LIFE
                                    </div>
                                    <v-list-item-title class="text-h6 mb-1">
                                        LAK <span class="red--text">{{total_policy.total_premium/1000000 | numeral('0,0.[0]')}}M</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{$t('MAIN.TOTAL_POLICY')}}: {{total_policy.total}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                        tile
                                        size="80"

                                >
                                    <v-icon size="80" color="error">mdi-heart-pulse</v-icon>
                                </v-list-item-avatar>
                            </v-list-item>

                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-card
                                class="mx-auto rounded-lg"
                                outlined
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                        EZY LIFE Gold
                                    </div>
                                    <v-list-item-title class="text-h6 mb-1">
                                        LAK <span class="red--text">{{total_ezg.total_premium/1000000 | numeral('0,0.[0]')}}M</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{$t('MAIN.TOTAL_POLICY')}}: {{total_ezg.total}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                        tile
                                        size="80"

                                >
                                    <v-icon size="80"  color="warning">mdi-gold</v-icon>
                                </v-list-item-avatar>
                            </v-list-item>

                        </v-card>
                    </v-col>
                </v-row>


                <v-divider class="my-4"></v-divider>
                <v-row class="pt-4">
                    <v-col cols="12" lg="2" md="3" sm="4" class="py-0">
                        <v-text-field
                                outlined
                                dense
                                v-model="year"
                                label="Year"
                                class="centered-input"

                                readonly
                                prepend-inner-icon="mdi-minus"
                                append-icon="mdi-plus"
                                @click:prepend-inner="year--"
                                @click:append="year++"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>

                    <v-col cols="12">
                        <line-chart v-show="false" v-if="chart_data_loaded" :chart-data="line_chart"></line-chart>
                    </v-col>
                    <v-col cols="12">
                        <apex-line :chart-title="{text:'Monthly Premium'}" :series="chartData"></apex-line>
                    </v-col>
                </v-row>

            </v-card-text>

        </v-card>

    </div>
</template>

<script>


    import LineChart from "@/components/LineChart";
    import ApexLine from "@/components/apex/ApexLine";

    export default {
        name: 'HomeView',
        components: {ApexLine,  LineChart},
        data: () => ({
            chart_data_loaded: false,
            year: new Date().getFullYear().toString().padStart(4, "0"),

            total_policy: {},
            total_pa_policy: {},
            total_ezg:{},
            agent_data: {},
            line_chart: {
                labels: [],
                datasets: [
                    {
                        label: 'EZY LIFE',
                        backgroundColor: '#ec6f71',
                        lineTension: 0.4,
                        data: []
                    },
                    {
                        label: 'PA',
                        backgroundColor: '#ff9800',
                        lineTension: 0.4,
                        data: []
                    },
                    {
                        label: 'EZY GOLD',
                        backgroundColor: '#c40d68',
                        lineTension: 0.4,
                        data: []
                    },

                ]
            },
            chartData: [{
                name: "Premium",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }],
        }),
        mounted() {
            this.getData()
        },
        watch: {
            year() {
                this.getData()
            }
        },
        methods: {
            async getData() {
                try {
                    this.chart_data_loaded = false
                    const r = await window.axios.get('backend/board', {params: {year: this.year}})
                    console.log(r.data)
                    // this.line_chart.labels = r.data.title
                    // this.line_chart.datasets[0].label=this.$t('MAIN.MONTHLY_PREMIUM')
                    // this.line_chart.datasets[0].data = r.data.amount
                    // this.line_chart.datasets[1].data = r.data.pa_amount
                    // this.line_chart.datasets[2].data = r.data.ezg_amount
                    this.total_policy = r.data.total_policy
                    this.total_pa_policy = r.data.total_pa
                    this.total_ezg = r.data.total_ezg
                    this.agent_data = r.data.agent_data

                    //apex chart
                    this.$set(this.chartData, 0, {
                        name: 'EZY LIFE',
                        data: r.data.amount
                    })
                    this.$set(this.chartData, 1, {
                        name: 'EZY GOLD',
                        data: r.data.ezg_amount
                    })
                    this.$set(this.chartData, 2, {
                        name: 'PA',
                        data: r.data.pa_amount
                    })


                    this.chart_data_loaded = true
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
</script>
<style scoped>
    >>>
    .centered-input input {
        text-align: center
    }

    /* Define the blinking animation */
    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    /* Apply the blinking animation to the circle */
    .blinking-circle {
        background-color: transparent; /* You can change the circle's color */
        animation: blink 2s infinite; /* 1 second blink, repeat infinitely */
    }
</style>